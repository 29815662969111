import styled from "styled-components";

export const MainLayout = styled.div`
  width: 1280px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1280px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Title = styled.div`
  font-size: 48px;
  font-weight: 600;
  color: rgba(225, 104, 43, 1);
  line-height: 65.38px;

  @media (max-width: 1280px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SubTitle = styled.div`
  margin-top: 24px;
  color: #333333;
  font-weight: 500;
  line-height: 33px;
  font-size: 24px;
  white-space: pre-wrap;

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
  }
`;
